<script>
import Layout from "../../layouts/main";
// import ReportStatus from "./wallet-chart.vue";
// import {pieChart} from "./data-apex";
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';

import axios from 'axios';

import base_domain from '../../myvars'
axios.defaults.baseURL = base_domain

export default {

  components: {
    Layout,
    EasyDataTable,
    // ReportStatus 
  },
  data() {
    return {
      Name:null,
      Status:null,
      Branch:null,
      PersonNo:[],
      MemberNo:[],
      customDateLog: null,
      PhoneNumber:null,
      Email:null,
      
      headers:[
            { text: "Date", value: "logdate" },
            { text: "Activity", value: "appactivity" },
        ],
        item: [
            { "dateLog": '10 August, 2023', timeLog: '03.00PM', "appactivity": "You re-submit your application"},
            { "dateLog": '15 July, 2023', timeLog: '02.00PM', "appactivity": "Your application seems to be Incomplete"},
            { "dateLog": '29 June, 2023', timeLog: '01.00PM', "appactivity": "Review your personal information step",},
            { "dateLog": '22 May, 2023', timeLog: '12.00AM', "appactivity": "Viewed your application",},
            { "dateLog": '20 May, 2023', timeLog: '05.00PM', "appactivity": "Your application has been Submitted. We will update you once your application will be changed.",},
        ],
        arrItem:[],
    }
  },
  mounted() {
    this.showdata();
    this.filterStatus();
    // this.filterStatusone();
  },
  created(){

  },
  methods: {
    filterStatus(){
      if(this.selectFilterSort==null)
      {
        this.arrItem = this.items
      }
      else{
        this.arrItem = this.items.filter((item)=>{
          return  item.level==this.selectFilterSort
      })
      }
    },
    // filterStatusone(){
    //   if(this.selectFilterSort==null)
    //   {
    //     this.item = this.items
    //   }
    //   else{
    //     this.item = this.items.filter((item)=>{
    //       return  item.level==this.selectFilterSort
    //   })
    //   }
    // },
    showdata(){
        const tooken = localStorage.getItem("accessToken");
        var app = window.location.href;
        const segments = app.split("/");
        const app_id = segments[segments.length - 1];
        console.log("this token is valid check kr liya " , tooken ,app_id);
        let arrList =[]
        axios.post('/applications/single_applicant/',{
            token:tooken,
            app_id: app_id
          })
          .then(response => {
              const data = response.data['applicant_details'];
              console.log('cheack the data came or not ',data)
              console.log('cheack the data came or not ',data.created_on)
              this.Name = `${data.section_a1 && data.section_a1.first_name || 'N/A'} ${data.section_a1 && data.section_a1.sur_name || ''}`;
              this.Status =  `${data.status || 'N/A'}`;
              this.Total_Applicants = `${data.Total_Applicants || 'N/A'}`;
              this.Branch = `${data.section_a1.branch || 'N/A'}`;
              this.PhoneNumber = `${data.section_a1.home_contact || 'N/A'}`;
              this.Email = `${data.section_a1.email || 'N/A'}` ;
              this.customDateLog = `${data.created_on || 'N/A'}`;
                
              const responseList = response.data['app_notifications'];
              console.log('check the variable ----', responseList);
              responseList.forEach((item) => {
                const dateObject = new Date(item.created_on);
                const formattedDate = dateObject.toISOString().slice(0, 10);
                let newItem  = {
                  dateLog:formattedDate,
                  appactivity:item.content,
                }
                
                console.log("this is id test ----", item);
                // Push the transformed item to the existing array
                arrList.push(newItem);
              });
              console.log("NEW ARRAY DATa",arrList);
              this.arrItem = arrList;

            })
            .catch(error => {
            console.error(error);
            });
      }
  }

};

</script>

<template>
  <Layout>
    <main class="py-4">
        <div class="row mb-3">
            <div class="col">
                <h2 class="font-size-22 text-theme-dark">Single Application</h2>
            </div>
        </div>
        <div class="row gx-3">
          <div class="col-lg-4 col-xl-5">
            <div class="card">
              <div class="card-body">
                  <div class="row mb-3">
                    <div class="col">
                      <h2 class="font-size-16 text-start">Application Info</h2>
                    </div>
                    <!-- <div class="col-auto">
                      <router-link to="" class="btn btn-theme btn-sm">View Member Application</router-link>
                    </div> -->
                  </div>
                  <ul class="listUlStyle mb-0">
                      <li><span class="font-weight-500"> Applicant Name : </span><span>{{Name}}</span></li>
                      <li><span class="font-weight-500"> Email : </span><span> {{ Email }} </span></li>
                      <li><span class="font-weight-500"> Phone Number : </span><span> {{PhoneNumber}} </span></li>
                      <li><span class="font-weight-500"> Status : </span><span>{{Status}}</span></li>
                      <li><span class="font-weight-500"> Branch : </span><span>{{Branch}}</span></li>
                      <!-- <li><span class="font-weight-500"> Person No. : </span><span> {{ PersonNo }} </span></li>
                      <li><span class="font-weight-500"> Member No. : </span><span> {{ MemberNo }} </span></li> -->
                      <li><span class="font-weight-500"> Date : </span><span> {{customDateLog }} </span></li>
                  </ul>
              </div>
            </div>

          </div>
          <div class="col-lg-8 col-xl-7">
            <div class="card">
                <div class="card-body">
                  <EasyDataTable
                    :headers="headers"
                    :items="arrItem"
                    :search-value="searchValueTable"
                    border-cell
                    theme-color="#df6a0d"
                    :rows-per-page="10"
                    buttons-pagination
                    table-class-name="table-custom-style">
                    <template #item-logdate="{dateLog , timeLog }">
                      <p class="mb-0 font-weight-500 text-nowrap">{{ dateLog}}</p>
                      <p class="mb-0 text-nowrap">{{timeLog}}</p>
                    </template>
                    <template #item-appactivity="{appactivity}">
                        <p class="mb-0">{{appactivity}}</p>
                    </template>
                  </EasyDataTable>
                </div>
            </div>
          </div>
        </div>
    </main>
  </Layout>
</template>